import React from 'react'
import MemberGrid from './MemberGrid';
import team from '../content/team/Team';
import NavBar from '../navbar/NavBar';

const Team = () => {

    return <>
        <NavBar index={5} />
        <div>
            <h1 className="title is-1">Team ACT</h1>
            <div className="box" style={{ background:'#eefac5' }}>
                <div className="columns is-multiline">
                    {
                        team.map((member) => {
                            return <div key={member.key} className="column is-one-quarter-desktop is-half-tablet">
                                <MemberGrid url={member.image} name={member.name} role={member.role} />
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </>
}

export default Team