
const teamInfo = [
    '1,Dr. Phani Konide,Executive Board President,n',
    '2,Dr. Spandana Jala,Co-Head - Finance Department,n',
    '3,Roshan Jala,Co Head - Activity Department,n',
    '4,Srimani Sandepudi,Head - HR Department,n',
    '5,V S M R K Hareesh Neelisetty, Co Head - Finance Department,n',
    '6,Siva Sai Ram Prasad Kodali,Co Head - Activity Department,n',
    '7,Sindhuja Chigurupati,Lead Software Engineer,n',
    '8,Ramya Turaga,Executive Board Member,n',
    '9,Keerthi Gandhapuneti,Executive Board Member,n',
    '10,Sandip Tiwari,Head - IT Department,n',
    '11,Anitha Rose Rani J,,n',
    '12,Sudhakar Kommuri,,n',
    '13,Nagamani,,n',
    '14,Kenneth Kundurthi,,n',
    '15,Azeez Shaik,,n',
    '16,Azeem Shaik,,n',
    '17,Anand Lam,,n',
    '18,Devi Prasad Moddu,,n',
    '19,Afzal Peera Shaik,,n',
    '20,Sujan Korrapati,,n',
    '21,Krishna Chaitanya Sadhana,,n',
    '22,Bharat Arja,,n',
    '23,Sujit Devarapalli,,n',
    '24,Samrat Bodapati,,n',
    '25,Nagaraju Naik,,n',
    '26,Sravanti Kota,,n',
    '27,Uday Kiran Pasem,,n',
    '28,Narendra Gudamsetty,,n',
    '29,Naresh Kopuri,,n',
    '30,Ashok Karyampudi,,n',
    '31,Chandu Mudigonda,,n',
    '32,Prakash Nelabhotla,,n',
    '33,Narendra Babu Kumba,,n',
    '34,Naveen Kumar Ch,,n',
    '35,Ehana Syed,,n',
    '36,Phaneendra,,n',
    '37,Sudhamani,,n',
    '38,Srinivasulu,,n',
    '39,Dr. Srikanth,,n',
    '40,Nikhil Praveen,,n',
    '41,Venkataswamy P,,n',
    '42,Rambabu,,n',
    '43,Venkateswarlu Orsu,,n',
    '44,Haribabu D,,n',
    '45,Baji,,n',
    '46,Rama Rao T,,n',
    '47,Pardhasaradhi,,n',
    '48,Harsha G,,n',
    '49,Kishore,,n',
    '50,Ravi P,,n',
    '51,Hrudaya Mary J,,n',
    '52,Selvaraj Jala,,n',
    '53,Chaitanya Tej Jala,Chairman of the Board,n',
]

const defaultImage = '/team/defaultimage.png';
const team = [];
let key = 0;
teamInfo.forEach((member) => {
    if(member.trim()) {
        const tempDetails = member.trim().split(',');
        const memberDetails = {};
        memberDetails['key'] = key;
        memberDetails['id'] = tempDetails[0];
        memberDetails['name'] = tempDetails[1];
        //memberDetails['role'] = tempDetails[2];
        memberDetails['role'] = '';
        if(tempDetails[3] === 'y') {
            memberDetails['image'] = 'team/' + tempDetails[0] + '.jpg';
        } else {
            memberDetails['image'] = defaultImage;
        }
        team.push(memberDetails);
        key++;
    }
});

export default team