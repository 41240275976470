import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../home/Home';
import AboutUs from '../aboutus/AboutUs'
import PersonalNote from '../personalnote/PersonalNote'
import Activities from '../activities/Activities'
import Team from '../team/Team'
import ContactUs from '../contactus/ContactUs'
import InNews from '../innews/InNews';
import ScrollToTop from './ScrollToTop';
import Error from '../error/Error'



const AppRouter = () => {
    return <Router>
        <ScrollToTop/>
        <Switch>
            <Route exact path='/'>
                <Home/>
            </Route>
            <Route exact path='/aboutus'>
                <AboutUs/>
            </Route>
            <Route exact path='/personalnote'>
                <PersonalNote/>
            </Route>
            <Route exact path='/activities/:navPath?' 
                render={(props) => (
                    <Activities {...props} />
                )} 
            />
            <Route exact path='/innews/:navPath?' 
                render={(props) => (
                    <InNews {...props} />
                )} 
            />
            <Route exact path='/team/:page' children={<Team/>}>
            </Route>
            <Route exact path='/contactus'>
                <ContactUs/>
            </Route>
            <Route path='/*'>
                <Error/>
            </Route>
        </Switch>
    </Router>
}

export default AppRouter
