import React from 'react'
import NavBar from '../navbar/NavBar'

const ContactUs = () => {
    return <>
        <NavBar index={6} />
        <div>          
            <h1 className="title is-1">info@albinacharitabletrust.com</h1>
        </div>
    </>
}

export default ContactUs