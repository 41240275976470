import React, {useEffect} from 'react'
import {useHistory} from "react-router-dom";

const Error = () => {
    const history = useHistory();

    useEffect(()=>{
        setTimeout(()=> history.push('/'),5000);
    })

    return <>
        <div>
            <p>The page you are looking for does not exist</p>
            <p>You will be routed to our home page shortly</p>
        </div>
    </>
}

export default Error