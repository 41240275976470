import './App.css';
import 'bulma/css/bulma.css'
import AppRouter from './components/router/AppRouter';
import React, {Component} from 'react';

class App extends Component {

  constructor(props) {
  
    // This will call the constructor of the parent
    super(props);
  
    // Taking reference of body element
    let bodyElement = document.getElementsByTagName('body')[0];
  
    // Changing the class of body Before mounting
    bodyElement.className = 'has-navbar-fixed-top';
    bodyElement.style.paddingTop='160px';
    bodyElement.style.backgroundColor='#eefac5';
    
  }
  
  componentDidMount() {
  }
  
  render() {
    return (
    <div className="App fill-window div">
      <AppRouter />
    </div>
  );
  }
  
}

export default App;