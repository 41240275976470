import React from 'react';
import styles from './Landing.module.css';
import { useHistory } from 'react-router-dom';

const NavBar = (props) => {
    const history = useHistory();
    const buttonIds = ['1', '2', '3', '4', '5', '6', '7'];
    const buttonTexts = ['Home', 'About Us', 'Personal Note', 'Activities', 'In News', 'Team', 'Contact Us'];
    const navPaths = ['/', '/aboutus', '/personalnote', '/activities', '/innews', '/team/1', '/contactus']

    const handleTabChange = (tabIndicator) => {
        if(parseInt(tabIndicator) === 4 || parseInt(tabIndicator) === 5) {
            history.push({
                pathname: navPaths[parseInt(tabIndicator) - 1],
                props: {
                    isForward: true
                }
            });
        } else {
            history.push({
                pathname: navPaths[parseInt(tabIndicator) - 1]
            });
        }
    }

    return <>
    <nav className='navbar is-fixed-top column is-full' role='navigation' aria-label='main navigation' style={{textAlign: 'center', backgroundColor:'#eefac5'}}>
        {
            buttonIds.map((buttonId, loopIndex) => {
                if(loopIndex === props.index) {
                    return <button data-testid={'nb' + loopIndex} className={`button ${styles.btnHgColor}`} onClick={() => {handleTabChange(buttonId)}}  key={buttonId} id={buttonId}>{buttonTexts[loopIndex]}</button>
                } else {
                    return <button data-testid={'nb' + loopIndex} className={`button ${styles.btnBgColor}`} onClick={() => {handleTabChange(buttonId)}}  key={buttonId} id={buttonId}>{buttonTexts[loopIndex]}</button>
                }
            })
        }
    </nav>
    </>
}

export default NavBar