
const curDecade = {
    summary: 'Some of the news coverage articles for the trust activities.',
    path: 'innews/2021-Present/images/',
    lastIndex: '32'
};

//escape character is \
const firstDecade = {
    summary: 'ACT in news during the trust\'s first decade of operations.',
    path: 'innews/2011-2021/images/',
    lastIndex: '10'
};

const innews = {
    navInfo: ['2011-2021', '2021-Present'],
    0: firstDecade,
    1: curDecade,
};

export default innews