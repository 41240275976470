import React from 'react'

const ImageGrid = (props) => {
    return <div className="card">
               <div className="card-image">
                 <figure className="image is-3by2">
                 <img 
                 data-testid={'ig' + props.image.key}
                 src={props.image.url} 
                 alt=""
                 onClick={() => props.handleOnClick(props.image.key)}></img>
               </figure>
               </div>
         </div>;
}

export default ImageGrid