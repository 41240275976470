import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import styles from './Home.module.css'
import NavBar from '../navbar/NavBar';

const slideImages = [
  'scroll/1.jpg',
  'scroll/2.jpg',
  'scroll/3.jpg',
  'scroll/4.jpg',
  'scroll/5.jpg',
  'scroll/6.jpg',
  'scroll/7.jpg',
  'scroll/8.jpg'
];

const properties = {
      duration: 4000,
      transitionDuration: 1000,
      arrows: false,
      easing: "ease",
    };

const Home = () => {
    return (
        <div >
            <NavBar index={0} />            
            <h1 className="title is-1">Albina Charitable Trust</h1>
            <h1 className="subtitle is-5">providing platform to ACT, for those with a heart</h1>
            <br/>
            <div className={`${styles.box} column is-three-fifths is-offset-one-fifth`}>
                <Slide {...properties}>
                   {slideImages.map((each, index) => (
                    <div key={index} >
                      <img src={each} alt="" />
                    </div>
                    ))}
                </Slide>
            </div>
            
        </div>
        
    )
    
}

export default Home