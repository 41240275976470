import React from 'react'
import styles from './memberGrid.module.css'

const MemberGrid = (props) => {
    return (
        <div >
            <div className={` card ${styles.mycard} `}>
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={props.url} alt="Placeholder`"/>
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="subtitle is-4">{props.name}</p>
                            <p className="title is-5">{props.role}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberGrid