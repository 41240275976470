import React from 'react'
import styles from './personalNote.module.css'
import NavBar from '../navbar/NavBar'

const PersonalNote = () => {
    return <>
        <NavBar index={2} />
        <div className={styles.div}>
        <div className={`column is-three-fifths is-offset-one-fifth ${styles.div}`}>
            <div className={styles.box}>
                <div>
                <p className={styles.text}>I have had the privilege of knowing all the founders of this organization personally. I can confidently state on behalf of all the founders that together we share a common belief of giving back to the society. It is with this belief that we started Albina Charitable Trust.
                <br /><br />
                Since the start of Albina Charitable Trust, I have had the opportunity to work closely with all of our members, volunteers and consultants who all share the same core belief of our organization. I am fortunate to be able to work with a team that is committed to service and to be a part of the Albina Charitable Trust family.
                <br /><br />
                At Albina Charitable Trust, our primary focus is to provide support for education to children who need it. However, we believe service cannot be restricted to any one kind and so we also conduct activities aimed at providing basic needs, creating awareness and protecting the environment. When we started Albina Charitable Trust, we made a conscious decision to not seek donations from the outside world right from the very start. I feel happy to announce that, thus far, we have run our organization solely from the contributions made by the members of our organization.
                <br /><br />
                To be able to write this message, today, on the occasion of Albina Charitable Trust's first anniversary is an achievement we are all immensely proud of. We realize this is only the first step. Over the next years we intend to expand our reach and serve more. As we continue our journey, we commit ourselves to supporting any valid cause. We would be glad to have anyone join us in process.
                <br /><br />
                Date: 2012-04-06 <br />
                Occasion: Albina Charitable Trust's first anniversary<br />
                <br /> <br />
            </p>
            </div>
            <div>
                <p className='is-size-5'>
                <b>Dr. Phani Konide</b><br />
                Executive Board President
                </p>
            </div>
            </div>
        </div>
        </div>
    </>
}

export default PersonalNote