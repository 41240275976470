import React, { useState} from 'react'
import ImageGrid from './ImageGrid.js';
import styles from './Page.module.css'
import LightboxWrapper from './LightboxWrapper.js'

const NewsPage = (props) => {
    const imagesMessage = 'Click on any image to see full scale images';
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    let summary = [];
    let images = [];

    const tmpActivities = props.activities;

    const imagesArray = [];
    for(var loopindex = 1; loopindex <= tmpActivities.lastIndex; loopindex++) {
        imagesArray.push('/' + tmpActivities.path + loopindex + '.jpg');
    }
    if(imagesArray.length > 0) {
        imagesArray.forEach((image, loopindex) => {
            let tmpObj = {};
            tmpObj['url'] = image;
            tmpObj['key'] = loopindex;
            imagesArray[loopindex] = tmpObj;
        });

        images = imagesArray;

        const descLines = tmpActivities.summary.split('\n');
        const tempLines = [];
        let tagKey = 0;
        descLines.forEach((line, loopIndex) => {
            let lines = line.split('|');
            let key = 0;
            for(var innerLoopIndex = 0; innerLoopIndex < lines.length; innerLoopIndex++) {
                tempLines.push(<b key={'s' + tagKey}>{lines[innerLoopIndex]}</b>);
                if(key < lines.length - 1) {
                    tempLines.push(<br key={'sb' + tagKey}></br>)
                }
                key++;
                tagKey++;
            }
            if(loopIndex < descLines.length - 1) {
                tempLines.push(<br key={'db' + loopIndex}></br>);
                tempLines.push(<br key={'db2' + loopIndex}></br>);
            } 
        });
        summary = tempLines;
    }

    const handleOnClick = (key) => {
        setIndex(key);
        setShow(true);
    }

    return <>
        <div className="column is-three-fifths is-offset-one-fifth">
            <div className={styles.box}>
                <p className={` subtitle is-5 ${styles.text} `} key={'summary'} >{summary}</p>
            </div>
        </div>
        <br/>
        <div ><i ><p className={` subtitle is-4 ${styles.bgColor} `}>{imagesMessage}</p></i></div>
        <br/>
        <div className="box" style={{backgroundColor: '#eefac5'}}>
            <div className="columns is-multiline">
            {
                images.map((image) => {
                    return <div key={image.key} className="column is-one-quarter-desktop is-half-tablet">
                        <ImageGrid image={image} handleOnClick={handleOnClick} />
                    </div>
                })
            }
            </div>
        </div>
        {show && <LightboxWrapper images={images} index={index} setShow={setShow} />}
        </>;
};

export default NewsPage