import React from 'react';
import {useParams, useHistory, Redirect} from 'react-router-dom';
import ActivitiesPage from '../contenthandler/ActivitiesPage';
import activities from '../content/activities/Activities';
import NavBar from '../navbar/NavBar';

const Activities = () => {
    const params = useParams();
    const history = useHistory();
    
    let tmpActivities = activities;
    let navPath = params.navPath;
    let page = 1;
    if(navPath) {
        let navPathElements = navPath.split('_');
        if(navPathElements.length > 0) {
            navPathElements.forEach((element) => {
                if(element.includes('page')) {      
                    page = element.replace('page', '');
                } else {
                    let travIndex = tmpActivities.navInfo.indexOf(element);
                    tmpActivities = tmpActivities[travIndex];
                }
            });
        }
    }

    if(!tmpActivities) {
        return <Redirect to="/wrongPage" />;
    }

    const showPage = tmpActivities.navInfo ? false : true;

    const handleEffect = (index) => {
        let navPath = params.navPath
        if(navPath) {
            navPath += '_' + tmpActivities.navInfo[index];
        } else {
            navPath = '/activities/' + tmpActivities.navInfo[index];
        }
        history.push({
            pathname: navPath,
            props: {
                isForward: true
            }
        });
    }

    return <>
        <NavBar index={3} />
        
        <div style={{backgroundColor: '#eefac5'}}>
            {
                showPage ? <ActivitiesPage history={history} activities={tmpActivities} page={page} navPath={navPath} /> : tmpActivities.navInfo.map((element, index) => {
                    return <button className="button" style={{ marginLeft: '.5rem', backgroundColor: '#d4b350' }}
                        onClick={() => {handleEffect(index)}} data-testid={'a' + index} key={index}>{element}</button>
                })
            }
        </div>
    </>
};

export default Activities