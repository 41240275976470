import React from 'react'
import styles from './aboutUs.module.css'
import NavBar from '../navbar/NavBar'

const AboutUs = () => {
    return <>
        <NavBar index={1} />
        <div className={`column is-three-fifths is-offset-one-fifth ${styles.div}`}>
            <div className={styles.box}>
                <p className={styles.text}>Albina Charitable Trust is a non-government, non-profit and service-oriented organization. It believes service cannot be restricted to some aspects only. It encourages personal involvement. It encourages monetary contribution. It encourages all forms of service. It stands by the fact that service no matter how small is equally important.
                <br /><br />
                Many times, people have the will to serve but lack a direction. Albina Charitable Trust aims at providing a platform for such people to ACT and to serve in a way they can. Over the years, it aims to bring these small individual efforts together, channelize such efforts and to use the combined great force to serve humanity.
                <br /><br />
                Albina Charitable Trust is always open to partnerships for the right cause. There are many individuals and organizations round the world providing their share of service. Albina Charitable Trust is committed to supporting any individual or organization willing to serve in all possible ways it can.
                <br /><br />
                Registered: Document Number 99, A Book IV of 2011 <br />
                Established: 06 April 2011<br />
                </p>
            </div>
        </div>
    </>
}

export default AboutUs